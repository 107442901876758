@font-face {
    font-family: "Roboto";
    src: url(./fonts/roboto/Roboto-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url(./fonts/roboto/Roboto-Italic.ttf);
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url(./fonts/roboto/Roboto-Medium.ttf);
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url(./fonts/roboto/Roboto-MediumItalic.ttf);
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url(./fonts/roboto/Roboto-Bold.ttf);
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url(./fonts/roboto/Roboto-BoldItalic.ttf);
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url(./fonts/roboto/Roboto-Black.ttf);
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url(./fonts/roboto/Roboto-BlackItalic.ttf);
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

body {
    background-color: #41286c;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
    font-family: "Roboto";
    text-decoration: none;
    line-height: 150%;
}


main{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding: 20px;

    .main-content{
        background-color: #FFF;
        height: 90vh;
        width: 100%;
        max-width: 1024px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        padding: 20px;


        .header{
            display: flex;
            align-items: flex-end;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 20px;
            img{
                height: 50px;
            }

            h1{
                font-size: 20px;
            }
        }

        .search{
            width: 100%;
            display: flex;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding-bottom: 20px;
            margin-bottom: 20px;

            input{
                flex: 1;
                margin-right: 20px;
                padding: 8px;
                font-size: 14px;
                border-radius: 5px;
                border-width: 1px;
            }

            button{
                padding: 8px 16px;
                cursor: pointer;
                border-radius: 5px;
                border: none;
                background-color: #41286c;
                color: #FFF;
                font-size: 14px;
                font-weight: bold;
            }
        }

        .invoice-table {
            font-family: Arial, Helvetica, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }
          
          .invoice-table td, .invoice-table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          .invoice-table tr:nth-child(even){background-color: #f2f2f2;}
          
          .invoice-table tr:hover {background-color: #ddd;}
          
          .invoice-table th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: #41286c;
            color: white;
          }

          .invoice-table a{
            text-decoration: underline;
          }
    }
    
}

